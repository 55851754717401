header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background: #fff;
  padding: 10px 0;
  border-bottom: 1px solid #e4e4e4;
  z-index: 11;
}

header .logo,
header .logo a {
  color: #111;
  font-size: 23px;
  line-height: 23px;
  text-decoration: none;
  font-weight: bold;
}

header .box {
  /*width:75%;*/
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

header .box-main {
  width: 50%;
}

header .box-chat {
  margin-right: auto;
  margin-left: 85px;
  position: relative;
}

header .menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

header .menu > li {
  margin-right: 40px;
  padding: 10px 0;
  float: left;
  position: relative;
}

header .menu li.has-submenu {
  padding-left: 20px;
}

header .menu li.has-submenu .arrowhead {
  position: absolute;
  top: 14px;
  left: 0;
  width: 10px;
  height: 12px;
}

header .menu li.has-submenu:hover .arrowhead {
  transform: rotate(180deg);
}
header .menu li.has-submenu:hover .info-dropdown {
  display: block;
}

header .menu .menu-drop_menu,
header .menu .info-dropdown {
  width: 200px;
  background: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;
}

header .menu .info-dropdown {
  box-shadow: 0 0 5px 0 rgb(170 170 170);
}

@media (max-width: 768px) {
  header .menu .info-dropdown {
    box-shadow: none;
    margin-top: 15px;
  }

  header .menu li.has-submenu {
    padding-left: 0;
  }

  header .menu li.has-submenu .arrowhead {
    display: none;
  }
}

header .menu .info-dropdown li {
  width: 100%;
}

header .menu .profile-dropdown ul {
  top: 100%;
  left: auto;
  right: 0;
}

header .menu .info-dropdown li,
header .menu .profile-dropdown ul li {
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  transition: all 0.5s;
}

header .menu .info-dropdown li span,
header .menu .info-dropdown li a,
header .menu .profile-dropdown ul li span,
header .menu .profile-dropdown ul li a {
  color: #000;
  padding: 12px 15px;
  display: block;
}

header .menu .info-dropdown li:hover,
header .menu .profile-dropdown ul li:hover {
  background: rgba(0, 0, 0, 0.3);
  transition: all 0.5s;
}

header .menu li:last-child {
  margin-right: 0;
}

header .menu li a {
  text-decoration: none;
  transition: all 0.5s;
  font-size: 13px;
  line-height: 13px;
  color: #111;
}

header .menu li:hover a {
  transition: all 0.5s;
  /*color: #66ba04;*/
}

header .profile-dropdown li:hover > ul {
  display: block;
  z-index: 999;
}

header .profile-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header .profile-info .item {
  cursor: pointer;
}

header .menu li > span {
  text-decoration: none;
  /*transition: all 0.5s;*/
  font-size: 13px;
  line-height: 13px;
  color: #111;
  cursor: pointer;
}

.list-profile .profile-info {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}

header .profile-info .item,
.list-profile .item {
  margin: 0 10px;
  font-size: 13px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

header .profile-info .item .count {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: #f95555;
  border-radius: 50%;
  font-size: 12px;
  color: #fff;
  box-sizing: border-box;
}

header .profile-info .item a,
.list-profile .item a {
  text-decoration: none;
  color: #111;
  font-size: 13px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

header .profile-info .item svg,
.list-profile .item svg,
header .profile-info .item img,
.list-profile .item img {
  margin-right: 5px;
  width: 15px;
}

header .profile-info .item .miniava,
.list-profile .item .miniava {
  height: 35px;
  border-radius: 17px;
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
}

header .profile-info .item .miniava .frame,
.list-profile .item .miniava .frame {
  width: 35px;
  min-width: 35px;
  height: 35px;
  margin-left: 10px;
  overflow: hidden;
  border-radius: 50%;
  object-fit: fill;
}

header .profile-info .item .miniava img,
.list-profile .item .miniava img,
header .profile-info .item .miniava svg,
.list-profile .item .miniava svg {
  width: 35px;
  height: 35px;
  object-fit: cover;
}

header .profile-info .item ul,
.list-profile .item ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

header .profile-info .item ul li,
.list-profile .item ul li {
  position: relative;
  display: inline-block;
  margin-right: -4px;
  text-align: center;
}

header .profile-info .item ul li a,
.list-profile .item ul li a {
  color: #111;
  text-decoration: none;
}

header .profile-info .item ul li ul,
.list-profile .item ul li ul {
  position: absolute;
  top: 100%;
  right: 0;
  width: 200px;
  background: #fff;
  border: 1px solid #e9e7e7;
  z-index: 9999;
}

header .profile-info .item ul li ul li,
.list-profile .item ul li ul li {
  display: block;
  width: 100%;
  margin: 0;
  text-align: left;
}

header .profile-info .item ul li ul li a,
.list-profile .item ul li ul li a,
header .profile-info .item ul li ul li button,
.list-profile .item ul li ul li button {
  display: block;
  padding: 15px 20px;
  color: #111;
  border-bottom: 1px solid #e9e7e7;
  background: #fff;
  font-size: 13px;
  text-decoration: none;
  transition: all 0.5s;
}

header .profile-info .item ul li ul li button {
  width: 100%;
}

header .profile-info .item ul li ul li.yamoney,
.list-profile .item ul li ul li.yamoney {
  background: #f4f4f4;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  color: #a4a4a4;
}

header .profile-info .item ul li ul li.yamoney .cash,
.list-profile .item ul li ul li.yamoney .cash {
  font-size: 13px;
  color: #000;
  margin-bottom: 5px;
}

header .profile-info .item ul li ul li.yamoney span,
.list-profile .item ul li ul li.yamoney span {
  font-size: 12px;
  display: block;
  margin-top: 5px;
  color: #111;
}

header .profile-info .item ul li ul li:hover a,
.list-profile .item ul li ul li:hover a,
header .profile-info .item ul li ul li:hover button,
.list-profile .item ul li ul li:hover button {
  color: #77bf22;
  transition: all 0.5s;
}

.feedback-form .head-registration {
  text-align: center;
  width: 90%;
}

.feedback-form .input-stars {
  width: 80%;
  text-align: center;
}

.feedback-form textarea {
  width: 100%;
  height: 100%;
  border-radius: 7px;
  border: 1px solid #888;
  box-sizing: border-box;
  outline: none;
  -moz-appearance: none;
  resize: none;
  color: #000;
  padding: 10px 70px 10px 15px;
  font-size: 13px;
}

.feedback-form .text-feedback {
  height: 100px;
  display: block;
}

.feedback-form .text-feedback .block {
  height: 100%;
}

.review-user-form .image {
  text-align: center;
  margin-bottom: 15px;
}

.review-user-form .image .box {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  overflow: hidden;
  background: url("./../../images/img-ava.png") no-repeat center;
  background-size: cover;
  margin: 0 auto;
}

.review-user-form .image img {
  width: 65px;
  height: 65px;
  object-fit: cover;
}

.review-user-form .head-registration {
  text-align: center;
  width: 100%;
}

.review-user-form .input-stars {
  width: 100%;
  text-align: center;
}

.review-user-form .input-stars .greenstar {
  margin-left: 10px;
  margin-right: 10px;
}

.review-user-form textarea {
  width: 100%;
  height: 100%;
  border-radius: 7px;
  border: 1px solid #888;
  box-sizing: border-box;
  outline: none;
  -moz-appearance: none;
  resize: none;
  color: #000;
  padding: 10px;
  font-size: 13px;
}

.review-user-form .text-feedback {
  height: 100px;
  display: block;
}

.review-user-form .text-feedback .block {
  height: 100%;
}

.menu-show-hover {
  display: block;
}

.valsec,
.rur {
  display: inline-block !important;
}

.sub-menu .valsec {
  padding-left: 5px !important;
}

.time-schedule-form input {
  padding: 0 10px !important;
  text-align: center;
}

.card-container {
  color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-commission-text{
  color: #000;
  margin-bottom: 10px !important;
}

.paid-bills-container{
  display: flex;
  font-size: 11px;
  color: #000;
}

.paid-bills-container p{
  margin: 0;
  margin-bottom: 3px;
}

.title-commission{
  width: 100%;
}



.commission-date {
  text-align: left;
}

.commission-description{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cookies-fixed {
  margin-left: -350px;
  left: 50%;
  width: 700px;
  border-radius: 15px;
  font-size: 12px;
  position: fixed;
  bottom: 0;
  padding: 0 15px 0 15px;
  display: flex;
  justify-content: space-between;
  height: 70px;
  align-items: center;
  background: #2c2c2c;
  color: #fff;
}

.cookies-fixed-hide {
  display: none;
}

.cookies-button {
  border-radius: 20px;
  background: #fff;
  padding: 10px 20px 10px 20px;
  color: #000;
  font-size: 13px;
  cursor: pointer;
}

.cookies-link {
  color: #fff;
}

.title-pop-up {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
}

.pop-up-text {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 24px;
  text-align: justify;
}

@media (max-width: 768px) {
  header {
    /*position: fixed;*/
    /*top: 0;*/
    /*left: 0;*/
    /*right: 0;*/
  }

  header .profile-info .item .miniava {
    margin-left: 0;
  }

  header .box .menu li ul {
    position: initial;
    display: block;
    padding-top: 0;
  }

  header .logo {
    width: 30%;
    margin-right: auto;
  }

  header .logo a {
    font-size: 17px !important;
  }

  header .login {
    /*	width:54%; */
    text-align: right;
  }

  header .login {
    text-align: right;
    margin-left: auto;
  }

  header .login ul li {
    width: 100%;
    float: none;
    padding: 0;
    margin: 0;
  }

  header .box {
    order: 3;
  }

  header ul {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  header ul li {
    width: 100%;
    text-align: left;
  }

  header ul li a {
    color: #111;
    text-decoration: none;
  }

  header ul li a:hover {
    color: #77bf22;
  }

  .header .box {
    clear: none;
    float: right;
    max-height: none;
  }

  .header .menu-icon {
    display: none;
  }

  .header .box {
    display: none;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.2s ease-out;
    position: absolute;
    top: 56px;
    left: 0;
    right: 10px;
    padding: 10px;
    background: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }

  .header .menu-icon {
    cursor: pointer;
    display: inline-block;
    float: right;
    position: relative;
    user-select: none;
    padding: 10px;
  }

  .header .menu-icon .navicon {
    background: #111;
    display: block;
    height: 2px;
    position: relative;
    transition: background 0.2s ease-out;
    width: 18px;
  }

  .header .menu-icon .navicon:before,
  .header .menu-icon .navicon:after {
    background: #111;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.2s ease-out;
    width: 100%;
  }

  .header .menu-icon .navicon:before {
    top: 5px;
  }

  .header .menu-icon .navicon:after {
    top: -5px;
  }

  .header .menu-btn {
    display: none;
  }

  .header .menu-btn:checked ~ .box {
    display: block;
    max-height: 240px;
  }

  .header .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
  }

  .header .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
  }

  .header .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
  }

  .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
  .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
  }

  .header .profile-info .item {
    margin: 0 5px;
  }

  .cookies-fixed {
    margin-left: -250px;
    width: 500px;
    text-align: left;
    gap: 15px;
  }
}

@media screen and (max-width: 543px) {
  header .login ul li button {
    width: 100%;
    padding: 10px 15px;
    border-radius: 0;
  }

  header .login ul li button:hover {
    background: rgba(0, 0, 0, 0.3);
  }

  .cookies-fixed {
    margin-left: -180px;
    width: 360px;
    text-align: left;
    gap: 15px;
  }

  .block-controls-column {
    flex-direction: column;
  }
}

.restore-link {
  cursor: pointer;
  text-decoration: underline;
}

.restore-link:hover {
  text-decoration: none;
}
