.heading .filters .line select {
    width: auto;
    /*min-width: 100px;*/
}
.heading .filters .line input {
    width: 100%;
}
.pointer {
    cursor: pointer;
}
.filter-main .filter-list.disable {
    display: none;
    height: 0;
    transition: all 0.1s linear;
    opacity: 0;
}
.catalog .item .block .user-buttons-mobile button {
    background: none;
}

img {
    font-size: 0;
}