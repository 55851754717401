.chat {
    --chat-sidebar-width: 260px;
    --chat-body-width: calc(var(--container-width) - var(--chat-sidebar-width));
    padding: 0 !important;
}

.chat-container {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    max-width: var(--container-width);
    margin: 0 auto;
    overflow-y: hidden;
}

.chat-list {
    position: relative;
    height: 100%;
    background-color: #fff;
}

.rce-container-clist {
    display: block;
    overflow: auto;
}

.rce-container-clist::-webkit-scrollbar {
    width: 5px;
}

.rce-container-clist::-webkit-scrollbar-thumb {
    background-color: rgba(49, 54, 63, .3);
    border-radius: 6px;
}

.rce-citem-avatar__online {
    left: unset;
    right: 18px;
}

.chat-list {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: var(--chat-sidebar-width);
    left: 0;
    max-width: 380px;
    overflow: auto;
    transition: left .2s;
}

.chat-list .rce-citem {
    transition: background-color .2s, color .2s;
}

.chat-list .rce-citem:hover {
    background-color: #77bf22 !important;
    color: #fff;
}

.chat-list .rce-citem-body--bottom-title {
    transition: color .2s;
}

.chat-list .rce-citem:hover .rce-citem-body--bottom-title {
    color: #fff;
}

.chat-body {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: var(--chat-body-width);
    max-width: var(--chat-body-width);
    display: flex;
    flex-direction: column;
    background-color: #e7e7e7;
    box-shadow: inset 1px 0 0 rgba(0, 0, 0, .2);
}

.message-list {
    flex: 1;
    min-width: 140px;
    overflow: auto;
    background-color: #e7e7e7;
}

.message-list::-webkit-scrollbar {
    width: 5px;
}

.message-list::-webkit-scrollbar-thumb {
    background-color: rgba(49,54,63, .3);
    border-radius: 6px;
}

.chat__empty-announcers-msg {
    width: 100%;
    padding: 60px 10px 0;
    font-size: 18px;
    text-align: center;
    color: #979797;
}

@media screen and (max-width: 640px) {
    .chat-list {
        position: absolute;
        left: -380px;
        z-index: 1;
    }

    .chat-body {
        max-width: 100%;
    }

    .chat-list.chat-list--open {
        width: 100%;
        max-width: unset;
        left: 0;
    }
}
