#history {
    width: 100%;
    margin-top: 28px;
}

#history .titlepage {
    margin-bottom: 15px;
}

#history .pagination {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

#history .pagination span {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    color: #111;
    transition: all 0.5s;
}

#history .pagination span.active {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    color: #fff;
    background: #77BF22;
}

#history .pagination span:hover {
    color: #77BF22;
    transition: all 0.5s;
    cursor: pointer;
}

#history .pagination .active:hover {
    color: #fff;
    transition: all 0.5s;
    cursor: pointer;
}

#history .history-table {
    width: 100%;
    border-collapse: collapse;
}

#history .history-table .right {
    text-align: right;
}
#history .history-table .center {
    text-align: center;
}

#history .history-table td,#history .history-table th {
    padding: 5px 7px;
    border-bottom: 1px solid black;
}
#history .history-table tr:nth-child(even) {
    background: #cccccc;
}
.table-responsive {
    min-height: .01%;
    overflow-x: auto;
}
@media screen and (max-width: 767px) {
    .table-responsive {
        width: 100%;
        overflow-y: hidden;
    }
    .table-responsive th,
    .table-responsive td {
        white-space: nowrap;
        font-size: 0.7rem;
    }
}